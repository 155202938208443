/** @jsx jsx */
import React from "react";
import { SupportImage } from "@/data";
import { jsx } from "@emotion/react";

import {
  supportSection,
  org,
  apoio,
  liga,
} from "../assets/styles/Support.styles";
import { secTitle, commonSection } from "../assets/styles/layout.styles";

const Support = () => {
  const {
    vertere,
    ministeiro,
    fractalVertical,
    sapientia,
    vitalis,
    obecon,
    celeritas,
    olimPocket,
    oblin,
  } = SupportImage;

  return (
    <section css={[commonSection, supportSection]}>
      <div className="container">
        <div className="row justify-content-center"> 
          <div className="col-lg-2"></div>
          <div css={org} className="col-lg-4 col-xs-12 col-sm-12">
            <h2 css={secTitle}>Organização</h2>
            <a href="https://institutovertere.org/" target="_blank">
              <img src={vertere} alt="Instituto Vertere" />
            </a>
          </div>
          <div css={apoio} className="col-lg-4 col-xs-12 col-sm-12">
            <h2 css={secTitle}>Apoio</h2>
            <a href="https://fractaltecnologia.com.br/" target="_blank">
              <img src={fractalVertical} alt="Fractal" style={{ width: 150 }} />
            </a>
          </div>
        </div>
        <div className="row">
          <div css={liga} className="col-lg-12 text-center">
            <h2 css={secTitle}>Liga das Olimpíadas</h2>
            <ul>
              <li>
                <a href="https://olimpiadadofuturo.com.br/" target="_blank">
                  <img src={sapientia} alt="Sapientia" />
                </a>
              </li>
              <li>
                <a href="https://olimpiadademedicina.org/" target="_blank">
                  <img src={vitalis} alt="Vitalis" />
                </a>
              </li>
              <li>
                <a href="https://obecon.org/" target="_blank">
                  <img src={obecon} alt="Obecon" />
                </a>
              </li>
              <li>
                <a href="https://olimpiadadeia.org/" target="_blank">
                  <img src={celeritas} alt="Celeritas" />
                </a>
              </li>
              <li>
                <a href="https://olimpiadapocket.com.br/" target="_blank">
                  <img src={olimPocket} alt="Olimpíada Pocket" />
                </a>
              </li>
              <li>
                <a href="https://obling.org/" target="_blank">
                  <img src={oblin} alt="Olimpíada Brasileira de Linguística" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Support;
