/** @jsx jsx */
import { css } from "@emotion/react";
import { colors } from "../styles/layout.styles";

export const footer1 = css`
  position: relative;
  padding: 60px 0 0;
  background: #ffffff;
  font-family: 'Titillium Web', sans-serif;

  /* .widget{
    width: 20%;
  } */
  @media (min-width: 320px) and (max-width: 767px) {
      .col{
        width:100%;
        flex: auto;
      }
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      .row{
        justify-content: center;
      }
      .col{
        width:33%;
        max-width: 33%;
        flex: auto;
        margin-bottom: 32px;
        flex-wrap: wrap;
      }
    }
`;

export const widget = css`
  position: relative;
  padding: 0;
  color: #050505;

  h3 {
    font-size: 32px;
    letter-spacing: 0;
    line-height:35.84px;
    margin: 0 0 16px;
    position: relative;
  }

  ul{
    margin: 0;
    padding: 0;
    li{
      font-size: 18px;
    }
  }

  p{
    font-size: 12px;
  }

  a{
    color:#050505 ;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-bottom: 40px;
    padding-left: 0;
    text-align:center ;
  }
`;

export const aboutWidget = css`
  & img {
    /* max-width: 100%;
    height: auto;
    margin-bottom: 34px; */
  }

  & p {
    font-size: 14px;
    letter-spacing: 0.56px;
    color: #050505;
    margin: 0;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 0;
    margin-top: 0;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    padding-right: 0;
  }
`;

export const contactWidget = css`
  
  & p {
    font-size: 14px;
    letter-spacing: 0.56px;
    color: #050505;
    margin: 0;

    &:first-of-type {
      margin-bottom: 32px;
      @media (min-width: 320px) and (max-width: 767px) {
        margin-bottom: 15px;
      }
    }

    & a {
      color: #aaaaaa;
      &:hover {
        color: ${colors.primary};
      }
    }
  }
  @media (min-width: 768px) and (max-width: 990px) {
    padding-left: 0;
  }
`;

export const footerBotton = css`
  border-top: 2px solid #1e1e1e;
  font-size: 16px;
  line-height: 0.8;
  color: #050505;
  padding: 41px 0 24px;
  margin-top:60px;

  h6{
    font-weight: 700;
  }

  @media (min-width: 320px) and (max-width: 767px) {
    margin-top:0 ;
  }

`

export const copyright = css`
  display: flex;
  justify-content: space-between;

  ul{
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      padding: 0 6px;
      a i{
        color: #050505;
      }
    }
  }

  & a {
    color: #aaaaaa;
    &:hover {
      color: #e62b4a;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    flex-direction:column-reverse ;
    align-items:center ;

    h6,p{
      text-align:center ;
      margin-bottom:20px ;
    }

    ul{
      margin-bottom:40px;
      text-align: center;
      li{
        &:first-of-type{
              display:block ;
              margin-bottom:20px ;
        }
        a i{
          padding:0 4px;
          font-size:30px;
        }
      }
    }
  }
`;

export const socialWidget = css`
  & ul {
    margin: 0;
    padding: 0;
    & li {
      padding: 0;
      margin: 0 0 0;
      list-style: none;
      display: block;
      & a {
        font-size: 14px;
        letter-spacing: 0.56px;
        color: #aaaaaa;
        margin: 0;
        display: block;
        text-align: left;
        &:hover {
          color: #fff;
        }
        & i {
          font-size: 16px;
          color: #fff;
          margin-right: 12px;
        }
      }
    }
  }

`;
