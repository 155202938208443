/** @jsx jsx */
import { css } from "@emotion/react";
import ptFlag from "@/images/flags/pt.svg";
import esFlag from "@/images/flags/es.svg";

import { colors } from "../styles/layout.styles";

export const header = css`
  position: absolute;
  display: flex;
  align-items: center;
  height: 97px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  background: ${colors.gray};
  padding: 0px 40px 0 45px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);

  &.fixedHeader {
    background-color: #efefef;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 999;
    border: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  }

  &.black_color {
    border-color: #e5e5e5;

    &.fixedHeader {
      background-color: #ffffff;
    }

    nav ul li a {
      color: #000000;
      &:after {
        background-color: #000000;
      }
    }

    nav ul ul {
      background-color: #ffffff;
      &:before {
        border-bottom: 5px solid #fff;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding: 0;
  }
`;

export const logo = css`
  text-align: left;
  padding: 0px;
  img {
    width: 100%;
    max-width: 190px;
    height: auto;
  }
`;

export const mainMenu = css`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ul {
    margin: 0;
    padding: 0;
    display: inline-block;

    & li {
      list-style: none;
      display: inline-block;
      line-height: 0.8;
      padding: 20px 6px;
      position: relative;

      & > .active a {
        color: #fff;
        &:after {
          width: 100%;
        }
      }

      & a {
        display: block;
        color: #343a40;
        font-size: 16px;
        line-height: 0.8;
        position: relative;
        font-family: "Titillium Web", sans-serif;
        font-weight: 600;
        letter-spacing: 0;

        &:hover {
          color: ${colors.primary};
          text-decoration: none;
          /* &:after {
            width: 100%;
            background-color: ${colors.primary};
          } */
        }

        /* &:after {
          position: absolute;
          width: 0;
          height: 1px;
          content: "";
          background: #fff;
          left: 0;
          right: 0;
          bottom: -5px;
          -moz-transition: all 0.4s ease-out;
          -webkit-transition: all 0.4s ease-out;
          transition: all 0.4s ease-out;
        } */
      }

      &:hover .sub-menu {
        visibility: visible;
        opacity: 1;
        top: 66px;
      }
    }

    & ul.sub-menu {
      padding: 25px 30px 13px;
      & li {
        margin: 0;
        padding: 0 0 13px;
        display: block;
        & a {
          font-size: 14px;
          line-height: 1.5;
          display: inline-block;
          &:after {
            bottom: 0;
          }
        }
      }
    }
  }

  button {
    align-self: center;
  }
`;

export const submenu = css`
  /* background-color: ${(props) => (props.dark ? "#ffffff" : "#000000")}; */
  background-color: #000000;
  width: 200px;
  z-index: 9;
  text-align: center;

  transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  visibility: hidden;
  opacity: 0;
  left: 50%;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100px;
  transform: translateX(-50%);
  z-index: 2;

  &:before {
    content: "";
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #000;
    z-index: 10;
  }
`;

export const navigator = css`
  position: relative;
  padding: 6px 0 0px;

  & a {
    display: inline-block;
    color: ${colors.primary};
    line-height: 0.8;
    font-size: 25px;
    margin: 0;

    &:hover,
    .active {
      color: #e62b4a;
    }

    &.search {
      margin-right: 16px;
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    padding-top: 16px;
    padding-right: 0px;

    & a {
      &.search {
        margin-right: 10px;
      }
    }
  }
`;

export const langSelector = css`
  position: relative;
  margin-left: 36px;
  display: flex;
  align-items: center;

  span {
    cursor: pointer;
  }
`;

export const langList = css`
  background-color: #efefef;
  position: absolute;
  height: 96px;
  width: 65px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #383838;
  box-shadow: 5px 5px 0px 0px #383838;
  top: 0;
  overflow: hidden;
  display: none;

  span {
    display: block;
    transition: all 0.19s linear 0s;
    -moz-transition: all 0.19s linear 0s;
    -webkit-transition: all 0.19s linear 0s;
    -ms-transition: all 0.19s linear 0s;
    -o-transition: all 0.19s linear 0s;
    padding: 4px 0;
    position: relative;
    display: flex;
    justify-content: center;

    &:first-of-type {
      padding: 0;
      display: flex;
      justify-content: flex-end;
      i {
        color: ${colors.primary};
        padding-right: 8px;
      }
    }

    &:nth-of-type(2) {
      &:after {
        content: " ";
        position: absolute;
        height: 1px;
        width: 50px;
        background-color: #868e96;
        bottom: 0;
      }
    }

    &:hover {
      background: #d6d6d6;
    }

    img {
      margin-left: 4px;
    }
  }
`;

export const langImage = {
  pt: ptFlag,
  es: esFlag,
};

export const inscrevaBt = css`
height: 40px;
line-height: 33px;
`
