/** @jsx jsx */
import { css } from "@emotion/react";

import { colors } from "../styles/layout.styles";

export const supportSection = css`
  background-color: ${colors.secondaryVar1};
  padding: 60px 0;
  border-radius: 200px 0 0 0;

  h2 {
    font-size: 20px;
    margin-bottom: 16px;
    line-height: 22.4px;
    font-weight: bold;
  }

  img {
    margin-right: 20px;
  }

  ul {
    li {
      padding: 0 20px;
      margin-bottom: 16px;
      display: inline-block;
      img {
        margin: 0;
      }
    }
  }

  @media (min-width: 320px) and (max-width: 767px) {
    border-radius: 90px 0 0 0;
    .container .row{
      flex-direction:column ;
    }
  }
`;

export const org = css`
    max-width: 230px;

   @media (min-width: 320px) and (max-width: 767px) {
    text-align:center ;
    max-width: 100%;
    h2, img{
      margin-bottom:30px;
    }
   }
`

export const apoio = css`
  max-width: 404px;

  @media (min-width: 320px) and (max-width: 767px) {
    text-align:center ;
    max-width: 100%;
    h2{
      margin-bottom:30px;
    }
    img{
      display:block ;
      margin:0 auto 30px;
    }
   }
`

export const liga = css`
  h2{
    margin:32px 0 16px;
  }
  ul{
    margin:0;
    padding:0;
  }
  @media (min-width: 320px) and (max-width: 767px) {
      ul{
        li{
          display:block;
          margin-bottom:30px;
        }
      }
  }
`
