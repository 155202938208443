/** @jsx jsx */
import React from "react";
import { Link } from "gatsby";
import { LogoImage, FooterImage } from "@/data";
import { jsx } from "@emotion/react";

import {
  secTitle
} from "../assets/styles/layout.styles";
import {
  footer1,
  widget,
  aboutWidget,
  contactWidget,
  copyright,
  footerBotton,
} from "../assets/styles/Footer.styles";

const Footer = () => {
  const { fractal } = LogoImage;
  const { appStore, playStore } = FooterImage;
  return (
    <footer css={footer1}>
      <div className="container">
        <div className="row">
          <div className="col">
            <aside css={[widget, aboutWidget]}>
              <a href="/">
                <img src={fractal} alt="" style={{ width: 200 }} />
              </a>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sit amet libero in augue volutpat varius. Proin at elit ac nibh facilisis fermentum sit amet at mauris.</p> */}
            </aside>
          </div>
          <div className="col">
            <aside css={[widget, contactWidget]}>
              <h3 css={secTitle}>Baixe o app</h3>
              <Link to="https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.vitalis">
              <img src={playStore} />
              </Link>
              <Link to="https://apps.apple.com/br/app/vitalis/id1492487445">
                <img src={appStore} />
              </Link>
            </aside>
          </div>

          {/* <div className="col">
            <aside css={[widget, contactWidget]}>
              <h3 css={secTitle}>Atendimento</h3>
              <h5>Central de relacionamento</h5>
              <p>
                <a href="mailto:contato@olimpiadademedicina.com.br">contato@olimpiadademedicina.com.br</a>
                </p>
                
            </aside>
          </div> */}
          <div className="col">
            <aside css={[widget, contactWidget]}>
              <h3 css={secTitle}>Perfis</h3>
              <ul>
                <li>
                  <a href="#quem-pode">Júnior Regular</a>
                </li>
                <li>
                  <a href="#quem-pode">Sênior Regular</a>
                </li>
                <li>
                  <a href="#quem-pode">Júnior Livre</a>
                </li>
                <li>
                  <a href="#quem-pode">Sênior Livre</a>
                </li>
              </ul>
            </aside>
          </div>
          <div className="col">
            <aside css={[widget, contactWidget]}>
              <h3 css={secTitle}>Olimpíada</h3>
              <ul>
                <li>
                  <a href="#quem-pode">Quem pode Participar?</a>
                </li>
                <li>
                  <a href="#como-participar">Como Participar?</a>
                </li>
                <li>
                  <a href="#edicoes">Edições</a>
                </li>
                <li>
                  <a href="#cronograma">Cronograma</a>
                </li>
              </ul>
            </aside>
          </div>
        </div>
      </div>

      <div css={footerBotton}>
        <div className="container">
          <div css={copyright}>
            <div>
              <h6>
                Vitalis - Olimpíada de Medicina © {new Date().getFullYear()}
              </h6>
              <p>Todos os direitos reservados.</p>
            </div>

            <div>
              <ul>
              <li>
                Siga a <strong>Vitalis</strong> nas redes
              </li>
                <li>
                  <a target="_blank" href="https://www.facebook.com/olimpiadademedicina.br/">
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/olimpiadademedicina.br/">
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
